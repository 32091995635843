import { template as template_a4460618271d4c66a4b79ebf5c6c8210 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_a4460618271d4c66a4b79ebf5c6c8210(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
