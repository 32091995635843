import { template as template_1c9fe93e325e41a285590302f25fbf21 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1c9fe93e325e41a285590302f25fbf21(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
