import { template as template_86f0461d551747e581057e073296d57b } from "@ember/template-compiler";
const FKLabel = template_86f0461d551747e581057e073296d57b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
